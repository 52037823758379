import React, { useState, useEffect } from 'react'
import axios from 'axios';
import OtpInput from "react-otp-input";
import OtpTimer from "otp-timer";
import { apiUrl } from '../../constants/defaultValue';
import { useForm } from "react-hook-form";
import ReactTypingEffect from 'react-typing-effect';
import CreatableSelect from 'react-select/creatable';
import { useSearchParams } from 'react-router-dom';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import {
    EmailShareButton,
    FacebookShareButton,
    WhatsappShareButton,
    TwitterShareButton,
    EmailIcon,
    FacebookIcon,
    TwitterIcon,
    WhatsappIcon,
} from "react-share";

import LoadingOverlay from 'react-loading-overlay';
import { styles } from '../../utils/Loader'
import { callApi } from '../../utils/Api';
export default function EarlyBirdForm() {
    const [searchParam, setSearchParam] = useSearchParams();
    const [prgId, setPrgId] = useState(undefined);
    const [refId, setRefId] = useState(undefined);
    const [programID, setProgramID] = useState(undefined);
    const [referID, setReferID] = useState(undefined);
    const prgIdHand = searchParam.get("prgid");
    const refIdHand = searchParam.get("refid");

    useEffect(() => {
        if (prgIdHand != undefined) {
            setPrgId(prgIdHand);
        }
        if (refIdHand != undefined) {
            setRefId(refIdHand);
        }

    }, [prgIdHand, refIdHand]);

    useEffect(() => {
        if (prgId != undefined) {
            localStorage.setItem("prgid", prgId.toString());

        }
        if (refId != undefined) {
            localStorage.setItem("refid", refId.toString());

        }
    }, [prgId, refId]);

    useEffect(() => {
        let pid = localStorage.getItem("prgid");
        let rid = localStorage.getItem("refid");
        setProgramID(pid);
        setReferID(rid);
    }, [programID, referID]);
    // console.log(programID, referID);

    // ****************************** all states ******************************

    const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectedServiceOptions, setSelectedServiceOptions] = useState([]);
    const [phoneNumber, setPhoneNumber] = useState(undefined)
    const [mobileMessage, setMobileMessage] = useState(undefined)
    const [statuscode, setStatusCode] = useState(0)
    const [otp, setOTP] = useState(undefined);
    const [referalcode, setReferalCode] = useState(undefined);
    const [otpMessage, setOtpMessage] = useState(undefined);
    const [email, setEmail] = useState(undefined)
    const [bussinessName, setBussinessName] = useState(undefined)
    const [bussinessLocation, setBussinessLocation] = useState(undefined)
    const [message, setMessage] = useState(undefined)
    const [intrestedIn, setIntrestedIn] = useState(false)
    const [intrestedInMessage, setIntrestedInMessage] = useState(undefined)
    const [referallinkdata, setReferallinkdata] = useState(undefined)
    //****************************** react hooks form ******************************
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    // ******************************** select tag options ******************************
    const [url, setUrl] = useState(undefined);
    const [rcode, setRcode] = useState(undefined);
    const [options, setOptions] = useState([]);
    const [serviceOptions, setServiceOptions] = useState([]);
    const [reviewFormLoading, setReviewFormLoading] = useState(false);
    const [shareMsg, setShareMsg] = useState(undefined);
    useEffect(() => {
        if (url && bussinessName) {
            setShareMsg(`Hello, ${bussinessName} has recommended MAYIFIND for you, a 0% commission based ecommerce platform. Register yourself as an early bird vendor and get lifetime benefits. Register using referral code ${rcode} or use this link `)

        }
    }, [url, bussinessName, rcode]);
    // ******************************************** Select tag functions ******************************

    const handleReasonChange = (e) => {
        // console.log("Add  reason => ", e)
        setSelectedOptions(e)
    }

    const handleCreateReason = (e) => {
        // console.log("New Reason => ", e)
        setSelectedOptions([...selectedOptions, { label: e, value: 1, key: 1, created: true }])

    }
    
    

    // ********************************************** function of otp generate ******************************

    const onSubmit = (data) => {
        setReviewFormLoading(true);
        let rfid = localStorage.getItem("refid");
        let pgid = localStorage.getItem("prgid");

        let data1 = {}
        if (prgId && rfid) {

            data1 = { phoneNumber: phoneNumber, api_key: 'registeruser', referal: rfid, program: pgid }

        } else {
            data1 = { phoneNumber: phoneNumber, api_key: 'registeruser' }
        }
        
        axios.post(`${apiUrl}/earlyReg/earlyCurd/generateOtp`, data1).then(res => {
           
            setMobileMessage(res.data);
            setReviewFormLoading(false);
            if (res.data.code === 200) {

            }
        }).catch(err => {
            console.log(err);
            setStatusCode(0)
            setReviewFormLoading(false);

        })
        setStatusCode(1)
    };

    // ********************************************** function of add data of details from  ******************************
    const onSubmitData = (data) => {
        setReviewFormLoading(true);
        var data1 = {}
        if (referalcode) {
            data1 = {
                email: email,
                referalCode: referalcode,
                bussinessName: bussinessName,
                bussinessType: selectedOptions,
                bussinessLocation: bussinessLocation,
                phoneNumber: phoneNumber,
                intrestedIn: false,
                api_key: 'registeruser'
            }
        } else {
            data1 = {
                email: email,
                bussinessName: bussinessName,
                bussinessType: selectedOptions,
                bussinessLocation: bussinessLocation,
                phoneNumber: phoneNumber,
                intrestedIn: false,
                api_key: 'registeruser'
            }
        }
        axios.post(`${apiUrl}/earlyReg/earlyCurd/addEarlyReg`, data1
        ).then(res => {

            setReviewFormLoading(false);
            setMessage(res.data.message);
            if (res.data.data[0]?.program) {
                localStorage.setItem("prgid", res.data.data[0]?.program.toString());
            }
            if (res.data.data[0]?._id) {
                localStorage.setItem("refid", res.data.data[0]?._id.toString());
            }
            if (res.data.code === 200) {
                createReferalLink();
                setStatusCode(3);
            }
        }).catch(err => {
            console.log(err);
            setReviewFormLoading(false);
        })
    }
    // ********************************************** function of resend otp ******************************
    const [resend, setResend] = useState('');
    const resendOTP = () => {
        axios.post(`${apiUrl}/earlyReg/earlyCurd/generateOtp`, { phoneNumber: phoneNumber, api_key: 'registeruser' }).then(res => {
            // console.log("res => ", res.data.message);

            if (res.data.code === 200) {
                setResend(res.data.message);
            }
        }).catch(err => {
            console.log(err);
            setStatusCode(0)

        })
    }

    // ********************************************** function of validate otp ******************************
    const handleOtp = (e) => {
        setReviewFormLoading(true);
        e.preventDefault();
        axios.post(`${apiUrl}/earlyReg/earlyCurd/validateOtp`, { otp: otp, phoneNumber: phoneNumber, api_key: 'registeruser' }).then(res => {
            setOtpMessage(res.data.message);
            console.log(res.data.data.userData);
            if (res) {
                setBussinessName(res.data.data.userData.bussinessName);
                setBussinessLocation(res.data.data.userData.bussinessLocation);
                setEmail(res.data.data.userData.email);
                setSelectedOptions(res.data.data.userData.bussinessType);
            }
            setReviewFormLoading(false);
            if (res.data.code === 200) {
                setStatusCode(2);
            }
        }).catch(err => {
            console.log(err);
            setReviewFormLoading(false);
        })
    }
    // ********************************************** function of handel intrest in ******************************
    const handleIntrestedIn = (e) => {
        e.preventDefault();
        axios.post(`${apiUrl}/earlyReg/earlyCurd/intrestedIn`,
            {
                phoneNumber: phoneNumber,
                intrestedIn: intrestedIn,
            }).then(res => {
                setIntrestedInMessage(res.data.message);
                if (res.data.code === 200) {
                    setStatusCode(4);
                }
            }).catch(err => {
                console.log(err);
            })
    }
    useEffect(() => {
        axios.post(`${apiUrl}/service/service/getAllserviceKeys`).then(res => {
            // console.log(res.data.data)
            let key = Object.keys(res.data.data);

            let dropdown = [];
            key.map(function (val, key) {
                dropdown.push({ value: val, label: val, key: key })
            }
            )
            setServiceOptions(dropdown)
        }).catch(err => {
            console.log(err)
        }
        )
        axios.post(`${apiUrl}/category/curd/getAllKeys`).then(res => {
            // console.log(res.data.data)
            let key = Object.keys(res.data.data);

            let dropdown = [];
            key.map(function (val, key) {
                dropdown.push({ value: val, label: val, key: key })
            }
            )
            setOptions(dropdown)
            options.concat(serviceOptions)
        }).catch(err => {
            console.log(err)
        }
        )
    }, [])
   

    // ********************************************** function of create referal link ******************************
    const createReferalLink = () => {
        var data = {
            phoneNumber: phoneNumber,
            email: email,
            name: bussinessName,
            api_key: 'registeruser'
        }

        axios.post(`${apiUrl}/earlyReg/earlyCurd/createReferralLink`, data
        )
            .then(res => {
                // console.log("res => ", res);
                if (res) {
                    setUrl(res.data.data.shortDestUrl)
                    setRcode(res.data.data.referalCode)
                    setReferallinkdata(res.data);
                }

            }).catch(err => {
                console.log(err);
            })
    }
    const [referralcodemsg, setReferralcodemsg] = useState(undefined);
    const handleReferalCode = () => {
        axios.post(`${apiUrl}/earlyReg/earlyCurd/checkReferalCode`,
            { referalCode: referalcode }).then(res => {
                // console.log("res => ", res);
                if (res) {
                    setReferralcodemsg(res.data.message);
                }
                if (res.data.code === 401) {
                    setReferalCode('')
                }

            }).catch(err => {
                console.log(err);
            })
    }
    // ********************************************** useeffects of otp and status code ******************************
    useEffect(() => {
    }, [otp]);

    useEffect(() => {

    }, [statuscode]);
    function refreshPage() {
        setTimeout(() => {
            window.location.reload(false);
        }, 500);
        console.log('page to reload')
    }
    function copytexttoclipboard(text) {
        var textArea = document.createElement("textarea");
        textArea.value = text + url;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("copy");
        textArea.remove();
    }
    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            borderBottom: '1px dotted #000',
            //   color: state.isFocused ? '#ff0000' : '#ff0000',

        }),
        // control: () => ({

        //   width: 200,
        // }),
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = 'opacity 300ms';

            return { ...provided, opacity, transition, color: state.isFocused ? 'blue' : 'red' };
        }
    }
    console.log(selectedServiceOptions);
    return (
        <div>
            <div className="page-title">
                <div className="container">
                    <a onClick={refreshPage}>
                        <div className="text-center">

                            <img src="assets/img/logo.png" className='logo' alt="Mayifind" />

                        </div>
                    </a>




                    <h1 className=" center">
                        Sell  <a style={{ color: "#ff0000" }} href="/">
                            <ReactTypingEffect
                                text={["Anything", "Anywhere", "Anytime"]}
                                speed={100} eraseSpeed={50} eraseDelay={1000} typingDelay={1000}
                            />
                        </a> <br /> Pay <span className='may-3'>0% </span>Commission
                    </h1>
                </div>
            </div>

            <div>
                {(() => {

                    switch (statuscode) {
                        case 1:
                            return (
                                <LoadingOverlay
                                    active={reviewFormLoading}
                                    spinner
                                    styles={styles()}
                                >
                                    <form className="hero-form form" onSubmit={(e) => { handleOtp(e) }}>
                                        <div className="container">
                                            <div className="main-search-form">
                                                <div className="form-row">
                                                    <div className="col-md-4"></div>
                                                    <div className="col-md-4 col-12 ">

                                                        <div className="form-group">
                                                            <label htmlFor="what" className="col-form-label" style={{ fontSize: "17px" }}>Your Mobile Number</label>
                                                            <input name="PhoneNumber" value={phoneNumber} type="text" className="form-control" disabled />
                                                        </div>

                                                        <br /><br />
                                                        <div className="form-group">
                                                            <label htmlFor="what" className="col-form-label" style={{ fontSize: "17px" }}>Enter Verification Code</label>
                                                            <OtpInput
                                                                onChange={setOTP}
                                                                numInputs={6}
                                                                separator={<span >-</span>
                                                                } inputStyle={{
                                                                    width: "4.2rem",
                                                                    height: "5rem",

                                                                    fontSize: "1.2rem",
                                                                    borderRadius: 4,
                                                                    color: "black",
                                                                    border: "1px solid rgba(0,0,0,0.3)"
                                                                }}

                                                                value={otp}
                                                            />
                                                            {otpMessage}


                                                        </div>
                                                        <div className="text-center">
                                                            <button type="submit" className="btn btn-primary width-100">Verify <span><i className='fa fa-long-arrow-right'></i></span></button>
                                                        </div>

                                                        <div className="text-center mt-2">
                                                            <OtpTimer
                                                                minutes={0}
                                                                seconds={60}
                                                                text="Resend in:"
                                                                ButtonText="Resend verification code"
                                                                resend={resendOTP}
                                                                buttonColor="#ff0000"
                                                                textColor="#ff0000"
                                                                background="transparent"
                                                                fontWeight="bold"
                                                            />
                                                        </div>

                                                        <div className="text-center">
                                                            {resend ? "OTP sent to your mobile number" : null}
                                                        </div>

                                                    </div>

                                                    <div className="col-md-4"></div>
                                                </div>
                                                <br /><br />
                                            </div>
                                        </div>
                                    </form>
                                </LoadingOverlay>

                            )
                        case 2:
                            return (
                                <LoadingOverlay
                                    active={reviewFormLoading}
                                    spinner
                                    styles={styles()}
                                >
                                    <form className="hero-form form" onSubmit={handleSubmit(onSubmitData)}>
                                    <div className="container">
                                        <div className="main-search-form">
                                            <div className="row">
                                                <div className="col-md-4"></div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor="what" className="col-form-label" style={{ fontSize: "17px" }}>Enter Your Details</label>
                                                        <input name="bussinessName" {...register("bussinessName", { required: "Bussiness Name is required", minLength: 2, pattern: /^[0-9a-zA-Z ]+$/ })} value={bussinessName || ''} onChange={(e) => { setBussinessName(e.target.value) }} type="text" className={bussinessName?.length > 3 && bussinessName?.length != null && bussinessName.match(/^[0-9a-zA-Z ]+$/) ? "form-control is-valid" : "form-control is-invalid"} id="what" placeholder="Business Name" required />
                                                        <span>{errors.bussinessName?.message}</span>
                                                        <span>{errors.bussinessName?.type === 'minLength' && "Bussiness Name should be greater than 2 letter"}</span>
                                                        <span>{errors.bussinessName?.type === 'pattern' && "Please enter a Valid Bussiness Name"}</span>
                                                    </div>
                                                    <div className="form-group">
                                                        {/* <input name="bussinessType" value={bussinessType || ''} onChange={(e) => { setBussinessType(e.target.value) }} type="text" className={bussinessType.length>3 && bussinessType.length!=null ?"form-control is-valid":"form-control is-invalid"} id="what" placeholder="Business Type" required /> */}
                                                        <CreatableSelect
                                                            isMulti
                                                            isClearable
                                                            name="selectedOptions"
                                                            value={selectedOptions}
                                                            defaultValue={selectedOptions}
                                                            options={options.concat(serviceOptions)}
                                                            onChange={(e) => handleReasonChange(e)}
                                                            onCreateOption={e => handleCreateReason(e)}
                                                            styles={customStyles}
                                                            // {...register("selectedOptions",{ required: "Services/Products is required"})}
                                                            placeholder="Add Products/Services"

                                                        // className={selectedOptions.length > 0 ? "select-creatable is-valid" : "select-creatable  is-invalid"}
                                                        />
                                                        {/* <span>{errors.selectedOptions?.message}</span> */}

                                                    </div>
                                                   
                                                    <div className="form-group">
                                                        <input name="bussinessLocation" {...register("bussinessLocation", { required: "Bussiness Location is required", minLength: 2, pattern: /^[a-zA-Z0-9\s,. '-]{3,}$/ })} value={bussinessLocation || ''} onChange={(e) => { setBussinessLocation(e.target.value) }} type="text" className={bussinessLocation?.length > 3 && bussinessLocation?.length != null && bussinessLocation.match(/^[a-zA-Z0-9\s,. '-]{3,}$/) ? "form-control is-valid" : "form-control is-invalid"} id="what" placeholder="Business Location" required />
                                                        <span>{errors.bussinessLocation?.message}</span>
                                                        <span>{errors.bussinessLocation?.type === 'minLength' && "Bussiness Location should be greater than 2 letter"}</span>
                                                        <span>{errors.bussinessLocation?.type === 'pattern' && "Please enter a Valid Bussiness Location"}</span>
                                                    </div>
                                                    <div className="form-group">
                                                        <input name="email" {...register("email", { required: "Email is required", pattern: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i })} value={email || ''} className={email?.length != null && email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) ? "form-control is-valid" : "form-control is-invalid"} onChange={(e) => { setEmail(e.target.value) }} type="email" id="what" placeholder="Your Email" required />
                                                        <span>{errors.email?.message}</span>

                                                        <span>{errors.email?.type === 'pattern' && "Please enter a Email"}</span>
                                                    </div>
                                                    {!programID && !referID ?
                                                        // <div className="form-group">
                                                        //     <input name="referalcode" onChange={(e) => { setReferalCode(e.target.value) }} type="text" id="what" placeholder="Referal code" />

                                                        // </div>
                                                        <>

                                                            <label htmlFor="what" className="col-form-label" style={{ fontSize: "17px", color: "#ff0000", fontWeight: 400 }}>Have a Referral Code ?</label>
                                                            <div className="input-group mb-3">

                                                                <input type="text" name="referalcode" value={referalcode} className="form-control mr-2" onChange={(e) => { setReferalCode(e.target.value) }} placeholder="Referral code" aria-label="Referal code" aria-describedby="button-addon2" />
                                                                <button className="btn btn-outline-secondary width-22 ml-2" onClick={handleReferalCode} type="button" style={{ color: "#ff0000", backgroundColor: "#fff" }} id="button-addon2">Check</button>
                                                            </div>



                                                            {referralcodemsg}
                                                        </>

                                                        : ''}


                                                    {message}
                                                    <button type="submit" className="btn btn-primary width-100">Submit</button>
                                                </div>
                                                <div className="col-md-4"></div>
                                            </div>
                                            <br /><br />
                                        </div>
                                    </div>
                                </form>
                                </LoadingOverlay>
                                
                            )
                        case 3:
                            return (
                                <div className="container">
                                    <div class="row flex-column-reverse flex-md-row">
                                        <div className="col-md-1"></div>
                                        <div class="col-md-10 mb-4">
                                            <hr />
                                            <section className='mt-4'>
                                                <h1 className='text-center mb-4'>M&nbsp;A&nbsp;Y<span className='may'>&nbsp;I&nbsp;</span>F&nbsp;I&nbsp;N&nbsp;D ??</h1>
                                                <p className='text-center' >
                                                    M A Y <span className='may'>I</span> F I N D is a <span className='may-2'>' 0% </span>Commission ' based new age method of doing business.
                                                    Alternately called as "Yellow Commerce", it promotes the businesses as well as the
                                                    business owners, so that buyers can ultimately
                                                    'find' them . Very clearly sellers don't have to pay commission on the transactions or
                                                    business generated using MayIFind's platform.
                                                </p>
                                            </section>

                                            <div className="text-center">
                                                <img src="assets/img/tq.png" className='logo' alt="" srcset="" />
                                                <p>Thank You for registering with  M A Y <span className='may'>I</span> F I N D !  Our expert team will stay in touch with you. Now you can also refer other vendors using the code or sharing option given below and get lifetime benefits.</p>
                                            </div>


                                            {shareMsg ? <div className="text-center ">

                                                <div className="mt-5 pt-4 mb-4">
                                                    <div className=" mt-4 mb-2 text-center">
                                                        <WhatsappShareButton style={{ marginRight: "15px" }} title={shareMsg} url={url}>
                                                            <WhatsappIcon size={32} round={true} />
                                                        </WhatsappShareButton>
                                                        <TwitterShareButton style={{ marginRight: "15px" }} title={shareMsg} url={url}>
                                                            <TwitterIcon size={32} round={true} />
                                                        </TwitterShareButton>
                                                        <FacebookShareButton style={{ marginRight: "15px" }} title={shareMsg} url={url}>
                                                            <FacebookIcon size={32} round={true} />
                                                        </FacebookShareButton>
                                                        {/* <EmailShareButton style={{ marginRight: "15px" }} title={shareMsg} url={url}>
                                                            <EmailIcon size={32} round={true} />
                                                        </EmailShareButton> */}

                                                    </div>

                                                    <p>
                                                        <div className="separator mt-4"> OR</div>
                                                    </p>
                                                    <button type="submit" className="btn btn-referalcode width-40 text-dark">{rcode}</button>


                                                    <p>

                                                    </p>

                                                </div>







                                            </div> : null}




                                            <form onSubmit={e => { handleIntrestedIn(e) }} >
                                                <div className="form-group">
                                                    {/* <input type="checkbox" value={intrestedIn} onChange={e => setIntrestedIn(true)} className='icheckbox' name="checkbox" /> */}
                                                    {/* <span>I am interested for an early bird offer. </span> */}
                                                    {/* <div className="row">
                                                        <div className="col-md-6 col-6"><button type="submit" className="btn btn-primary width-100">I am interested for an early bird offer<span><i className='fa fa-long-arrow-right'></i></span></button></div>
                                                        <div className="col-md-6 col-6"><button type="submit" className="btn btn-primary width-100">No Thanks <span><i className='fa fa-long-arrow-right'></i></span></button></div>
                                                    </div> */}


                                                </div>
                                                {intrestedInMessage}
                                                {/* <button type="submit" className="btn btn-primary h-25 width-10">Submit</button> */}
                                            </form>
                                            <br />

                                        </div>
                                        <div className="col-md-1"></div>
                                    </div>
                                </div>


                            )
                        case 4:
                            return (
                                <div className="container mt-4 mb-4">
                                    <h4 className='text-center mt-4 mb-4 '>Thank you for providing us your valuable details! You will get the best offer very soon</h4>
                                </div>
                            )
                        default:
                            return (
                                <>
<LoadingOverlay
                                    active={reviewFormLoading}
                                    spinner
                                    styles={styles()}
                                >
                                    <br />
                                    <form className="hero-form form" onSubmit={handleSubmit(onSubmit)}>
                                        <div className="container">
                                            <div className="main-search-form1">
                                                <div className="form-row">
                                                    <div className="col-md-4"></div>
                                                    <div className="col-md-4 col-sm-12 ">
                                                        <div className="form-group">

                                                            <label htmlFor="what" className="col-form-label" style={{ fontSize: "17px" }}>Enter your Mobile Number</label>
                                                            <input name="PhoneNumber" className="form-control"  {...register("phoneNumber", { required: "phone Number is required", maxLength: 10, pattern: /^[6-9]\d{9}$/ })} value={phoneNumber} onChange={(e) => { setPhoneNumber(e.target.value) }} type="text" placeholder="Mobile Number" required />
                                                            <span>{errors.phoneNumber?.message}</span>
                                                            <span>{errors.phoneNumber?.type === 'maxLength' && "Phone Number should be 10 digit"}</span>
                                                            <span>{errors.phoneNumber?.type === 'pattern' && "Please enter a Valid Phone Number"}</span>
                                                        </div>
                                                        <br />
                                                        <div className="form-group">

                                                            <input type="checkbox" className='icheckbox1' id='cb1' name="checkbox" required />

                                                            <span className='checkproperty' style={{ fontSize: "12px" }}>No T&C Applied on 0% Commission ? <span style={{ color: 'black' }}>Count Me In.</span>  </span>


                                                        </div>
                                                        <button type="submit" className="btn btn-primary width-100"

                                                        >Verify</button>
                                                    </div>

                                                    {mobileMessage}

                                                    <div className="col-md-4"></div>
                                                </div>

                                                <br /><br />
                                            </div>
                                        </div>
                                    </form>
                                </LoadingOverlay>
                                    </>
                            )
                    }
                })()}
            </div>
            {
                statuscode !== 3 ? <div className="container">
                    <div class="row flex-column-reverse flex-md-row">
                        <div className="col-md-1"></div>
                        <div className="col-md-10 mb-4">
                            <hr />
                            <section className='mt-4 mb-4'>
                                <h1 className='text-center mb-4'>M&nbsp;A&nbsp;Y<span className='may'>&nbsp;I&nbsp;</span>F&nbsp;I&nbsp;N&nbsp;D ??</h1>
                                <p className='text-center' >
                                    M A Y <span className='may'>I</span> F I N D is a <span className='may-2'>' 0%</span> Commission ' based new age method of doing business.
                                    Alternately called as "Yellow Commerce", it promotes the businesses as well as the
                                    business owners, so that buyers can ultimately
                                    'find' them . Very clearly sellers don't have to pay commission on the transactions or
                                    business generated using MayIFind's platform.
                                </p>
                            </section>
                            <br />
                            <hr />
                        </div>
                        <div className="col-md-1"></div>
                    </div>
                </div> : null
            }

            <NotificationContainer />

            <div className="background">
                <div className="background-image">
                    <img src="assets/img/footer-background-icons.jpg" alt="" />
                </div>
            </div>
        </div>
    )
}
